.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem;
  }
  
  .leftColumn {
    flex: 1;
    padding-right: 1rem;
  }
  
  .rightColumn {
    flex: 1;
    display: flex;
    max-height: 50vh;
    justify-content: center;
    align-items: center;
  }
  
  .rightColumn img {
    max-width: 100%;
    height: auto;
    max-height: 50vh;
  }
  
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
      padding: 1rem;
    }
  
    .leftColumn {
      padding-right: 0;
      padding-bottom: 1rem;
    }
  
    .rightColumn {
      padding-top: 1rem;
    }
  }

  .bioShort{
    font-size: 24px;
    text-align: start;
  }

.massiveFont{
    font-size: 50px;
}

