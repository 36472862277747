header {
  background-color: #333;
  padding: 1rem;

  @media (min-width: 768px) {
    padding: 2rem;
  }
}

.navbar {
  height: 5vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navToggler {
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
}

.pageTitle {
  justify-self: left;
  font-size: 16px;
}

.mobileNav {
  height: 95vh;
  width: 100%;
  z-index: 10;
  background-color: #333;
}

.navLinks {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0;
  margin-top: 2rem;

  @media (min-width: 768px) {
    flex-direction: row;
    margin: 0px;
    align-items: center;
    align-content: center;
    justify-content: space-around;
    width: 60vw;
  }

  .linkNavigation {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  .linkSocials {
    justify-self: right;
    display: flex;
    align-items: flex-end;
    gap: 20px;

    @media (max-width: 768px) {
      justify-content: space-around;
      align-items: center;
    }
  }
}

a {
  text-decoration: none;
  color: white;
}

.icons {
  width: 20px;
  height: 20px;
  color: white;
}
